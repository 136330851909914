<template>
  <b-row>
    <b-col md="12">
      <b-card no-body>
        <b-card-header>
          <!-- title and subtitle -->
          <div>
            <b-card-title class="mb-1">
              Statistique CEV
            </b-card-title>
            <b-card-sub-title>Rapport du nombre de CEV sur le temps </b-card-sub-title>
          </div>
          <!--/ title and subtitle -->

          <!-- badge -->
         
          <!--/ badge -->
        </b-card-header>

        <b-card-body >
          <vue-apex-charts type="line" height="400" :options="chartReportOptions" :series="reports" />
        </b-card-body>
       




        <b-row>
          <b-col lg="3" sm="6">
            <statistic-card-horizontal icon="CpuIcon" :statistic="`${statistics.cev_delivre_percent}%`"
              statistic-title="Taux de CEV Délivré" />
          </b-col>
          <b-col lg="3" sm="6">
            <statistic-card-horizontal icon="ServerIcon" color="success"
              :statistic="`${statistics.cev_non_delivre_percent}%`" statistic-title="Taux CEV Rejeté" />
          </b-col>
          <b-col lg="3" sm="6">
            <statistic-card-horizontal icon="ActivityIcon" color="danger" :statistic="statistics.cev_delivre"
              statistic-title="Nombre CEV Accepté" />
          </b-col>
          <b-col lg="3" sm="6">
            <statistic-card-horizontal icon="AlertOctagonIcon" color="warning" :statistic="statistics.cev_non_delivre"
              statistic-title="Nombre CEV Rejeté" />
          </b-col>
        </b-row>




      </b-card>
    </b-col>




    <b-col md="4">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Délivré
          </h4>
          <b-card-text class="font-medium-5 mb-0">
            <feather-icon icon="HelpCircleIcon" size="21" class="text-muted cursor-pointer" />
          </b-card-text>
        </b-card-header>

        <vue-apex-charts type="radialBar" height="210" :options="goalOverviewRadialBar.chartOptions"
          :series="goalOverviewRadialBar.series" />
       
      </b-card>

    </b-col>
    <b-col md="4">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Non délivré
          </h4>
          <b-card-text class="font-medium-5 mb-0">
            <feather-icon icon="HelpCircleIcon" size="21" class="text-muted cursor-pointer" />
          </b-card-text>
        </b-card-header>

        <vue-apex-charts type="radialBar" height="210" :options="goalOverviewRadialBarNonDelivre.chartOptions"
          :series="goalOverviewRadialBarNonDelivre.series" />

      </b-card>

    </b-col>
    <b-col md="4">
      <b-card no-body>
        <statistic-card-horizontal icon="ActivityIcon" color="danger" :statistic="statistics.cev_delivre"
          statistic-title="Nombre CEV Accepté" />
        <statistic-card-horizontal icon="AlertOctagonIcon" color="warning" :statistic="statistics.cev_non_delivre"
          statistic-title="Nombre CEV Rejeté" />
        <b-col>

        </b-col>

      </b-card>

    </b-col>

    <b-col md="12">
      <b-card title="Rapport" no-body>
        <b-card-body>
          <b-row>
            <b-col md="10" class="mb-5">
              <div class="demo-inline-spacing">
                <b-form-checkbox checked="true" v-model="type_de_document" switch inline>
                  Type de document
                </b-form-checkbox>
                <b-form-checkbox checked="true" v-model="date_d_abonnement" switch inline>
                  Date de génération
                </b-form-checkbox>
                <b-form-checkbox checked="true" v-model="mombre_cev_genere" switch inline>
                  Nombre de CEV généré
                </b-form-checkbox>
                <b-form-checkbox checked="true" v-model="mombre_de_cev_restant" switch inline>
                  Nombre de CEV restant 
                </b-form-checkbox>
              </div>
              
             
            </b-col>
            <b-col md="2" class="mb-5">
              <div class="demo-inline-spacing">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click.prevent="exportExcel"
                >
                  Exporter excel
                </b-button>
              </div>
             
            </b-col>
            


          </b-row>

          <div class="d-flex justify-content-between  flex-wrap">
            <!-- sorting  -->
            <b-form-group label="Trier" label-size="sm" label-align-sm="left" label-cols-sm="2" label-for="sortBySelect"
              class=" mb-md-0">
              <b-input-group size="sm">
                <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions">
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group label="Filtrer" label-cols-sm="2" label-align-sm="left" label-size="sm" label-for="filterInput"
              class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Rechercher" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>

        </b-card-body>
        <b-table striped hover responsive small :busy="loader" class="position-relative" :per-page="perPage"
          :current-page="currentPage" :items="statistics.rapport" :fields="fields" :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn"
          @filtered="onFiltered">
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner style="width: 2rem; height: 2rem;" variant="primary" label="Large Spinner" type="grow"
                class="mt-2" />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

        
          <!-- <template #cell(status)="data">
            <b-badge pill :variant="`${resolveUserStatusVariant(data.item.status)}`" class="text-capitalize">
              {{ data.item.status }}
            </b-badge>
          </template> -->
          <!-- Column: Actions -->
          <template>
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item v-b-modal.validation>
                <feather-icon icon="UserCheckIcon" class="text-success" />
                <span class="align-middle ml-50 text-success">Rapport</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>
        </b-table>
      </b-card>

    </b-col>

  </b-row>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge, BRow, BCol, BFormSelect, BFormInput, BInputGroupAppend,
  BButton, BFormGroup, BCardText, BInputGroup, BTable, BSpinner, BFormCheckbox, VBModal,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'
import { $themeColors } from '@themeConfig'
import AppEchartDoughnut from '@core/components/charts/echart/AppEchartDoughnut.vue'
import useStructures from '@/services/structure/structureService'
import { onMounted, ref, watch } from '@vue/composition-api'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import xlsx from 'xlsx/dist/xlsx.full.min';
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    AppEchartDoughnut,
    BRow, BCol,
    StatisticCardHorizontal,
    BFormSelect,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormGroup,
    BCardText,
    BInputGroup,
    BTable,
    BSpinner,
    BFormCheckbox,
    flatPickr,
  },
  directives: {
        'b-modal': VBModal,
        Ripple,
    },
  setup() {
    const {
      getStatisticsByStructure, statistics, serials, series, reports, chartReportOptions, goalOverviewRadialBar, goalOverviewRadialBarNonDelivre,
      loader, filterStatistics, hider,
    } = useStructures()

    onMounted(async () => {
      await getStatisticsByStructure()
    })

    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }
    const type_de_document = ref(true)
    const date_d_abonnement = ref(false)
    const nom_structure = ref(false)
    const localisation_structure = ref(false)
    const mombre_cev_genere = ref(false)
    const mombre_de_cev_restant = ref(false)

    const date = ref(null)
      const dateDefault= ref(null)
      const timePicker= ref(null)
      const dateNtim= ref(null)
      const multiDate= ref(null)
      const rangeDate= ref(null)
      const humanDate= ref(null)
      const disableDate= ref(null)
      const inlineDate= ref(null)

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    const fields = ref([
      { key: 'index', label: 'N°' },
      { key: 'type_document', label: 'Type de document', sortable: true },
      { key: 'status', label: 'Status', sortable: true },
      // { key: 'actions' },
    ])

    const reportFil = ref([
      { key: 'index', label: 'N°' },
      { key: 'status', label: 'Status', sortable: true },
    ])


    watch(() => (type_de_document.value), async value => {
      if (value ===false) {
       
      fields.value.pop(
        { key: 'type_document', label: 'Type de document', sortable: true }
      )
      } else {
        fields.value.push(
        { key: 'type_document', label: 'Type de document', sortable: true }
        
      )
      }
    })
    watch(() => (nom_structure.value), async value => {
      if (value ===true) {
        fields.value.push(
        { key: 'nom_de_la_structure', label: 'Nom de la structure', sortable: true }
        
      )
      reportFil.value.push(
        { key: 'nom_de_la_structure', label: 'Nom de la structure', sortable: true }
      )

      } else {
        fields.value.pop(
        { key: 'nom_de_la_structure', label: 'Nom de la structure', sortable: true }
      )
      }
    })
    watch(() => (localisation_structure.value), async value => {
      if (value ===true) {
        fields.value.push(
        { key: 'localisation_de_la_structure', label: 'Localisation de la structure', sortable: true }
        
      )
      reportFil.value.push(
        { key: 'localisation_de_la_structure', label: 'Localisation de la structure', sortable: true }
      )

      } else {
        fields.value.pop(
        { key: 'localisation_de_la_structure', label: 'Localisation de la structure', sortable: true }
      )
      }
    })
    watch(() => (mombre_cev_genere.value), async value => {
      if (value ===true) {
        fields.value.push(
        { key: 'mombre_de_cev_genere_par_la_structure', label: 'CEV généré par la structure', sortable: true }
        
      )
      reportFil.value.push(
        { key: 'mombre_de_cev_genere_par_la_structure', label: 'CEV généré par la structure', sortable: true }
      )

      } else {
        fields.value.pop(
        { key: 'mombre_de_cev_genere_par_la_structure', label: 'CEV généré pzr la structure', sortable: true }
      )
      }
    })
    watch(() => (mombre_de_cev_restant.value), async value => {
      if (value ===true) {
        fields.value.push(
        { key: 'mombre_de_cev_restant_pour_la_structure', label: 'Nombre CEV restant pour la structure', sortable: true }
        
      )
      reportFil.value.push(
        { key: 'mombre_de_cev_restant_pour_la_structure', label: 'Nombre CEV restant pour la structure', sortable: true }
      )

      } else {
        fields.value.pop(
        { key: 'mombre_de_cev_restant_pour_la_structure', label: 'Nombre CEV restant pour la structure', sortable: true }
      )
      }
    })

    const exportExcel= () =>{
      const XLSX = xlsx;
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(statistics.value.rapport_exel);
      XLSX.utils.book_append_sheet(workbook, worksheet, "ecev");
      XLSX.writeFile(workbook, "ecev.xlsx");
    }
    watch(() => (date_d_abonnement.value), async value => {
      if (value ===true) {
        fields.value.push(
          { key: 'created_at', label: 'Date de génération', sortable: true },
      )

      } else {
        fields.value.pop(
          { key: 'created_at', label: 'Date de génération', sortable: true },
      )
      }
    })
    watch(() => (multiDate.value), async value => {

        if (value.length ===22) {
          await filterStatistics(value.split(','))
        }
        if (value.length > 22) {
          multiDate.value=[]
        }
    })


    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      infoModal,
      fields,
      onFiltered,


      statistics,
      series,
      serials,
      apexChatData,
      reports,
      chartReportOptions,
      goal_overview: {},
      goalOverviewRadialBar,
      goalOverviewRadialBarNonDelivre,
      fields,
      loader,
      type_de_document,
      date_d_abonnement,
      exportExcel,
      date,
      dateDefault,
      timePicker,
      dateNtim,
      multiDate,
      rangeDate,
      humanDate,
      disableDate,
      inlineDate,
      hider,
      nom_structure,
      localisation_structure,
      mombre_cev_genere,
      mombre_de_cev_restant,

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  
 

}
</script>


<style lang="scss">
.card-body {
  position: relative;

  .pie-text {
    width: 105px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
}
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
